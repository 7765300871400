<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">报名咨询（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">报名咨询列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-input
                v-model="compName"
                type="text"
                size="small"
                clearable
                placeholder="请输入机构名称"
              />
            </div>
            <div title="所属区域" class="searchboxItem ci-full">
              <span class="itemLabel">所属区域:</span>
               <el-cascader
                :options="areatreeList"
                clearable
                filterable
                :props="propsarea"
                size="small"
                v-model="areaId"
              ></el-cascader>
            </div>
            <el-button
              style="margin-left:20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <div class="btnBox" style="margin-left:20px">
              <el-button class="bgc-bv" round @click="handleCreate('add')"
                >新增报名咨询机构</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />

              <el-table-column
                label="机构名称"
                align="center"
                prop="compName"
                show-overflow-tooltip
                min-width="100"
              />

              <el-table-column
                label="联系方式"
                align="center"
                prop="phone"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="培训专业"
                align="center"
                prop="trainingContent"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="所属地区"
                align="center"
                prop="areaNamePath"
                show-overflow-tooltip
                min-width="100"
              />
              
              <el-table-column
                label="操作"
                align="center"
                width="320px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="handleCreate('edit', scope.row.compId)"
                  >编辑
                  </el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:7px 10px"
                    @click="handleDel(scope.row.compId)"
                    >删除</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="seeShare(scope.row)"
                    >查看分享吗</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
     <!-- 查看分享吗的弹框 -->
    <el-dialog
      :title="seeShareData.title + '-分享码'"
      :visible.sync="seeShareDialog"
      width="30%"
    >
      <img
        style="width: 60%; display: block; margin: auto"
        :src="seeShareData.url"
        alt=""
      />
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="bgc-bv"
          size="small"
          round
          @click="downloadShare"
          >下载小程序码</el-button
        >
      </span>
    </el-dialog>  
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "teacherList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogFormVisible: false,
      compName: "",
      areaId:'',
      options: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      areatreeList:[],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      //  二维码弹框数据
      seeShareData: {
        url: "", // 二维码路径
        title: "", // 弹框标题
      },
      // 二维码弹框 - 状态
      seeShareDialog: false,
    };
  },
  computed: {},
  created() {
    this.getareatree();
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        compName: this.compName,
        areaId: this.areaId || null,
      };
      this.doFetch(
        {
          url: "/miniapp/company/list",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    //新增
    handleCreate(stu, compId = "") {
      this.$router.push({
        path: "/web/miniApp/registrationConsultationEdit",
        query: { compId, stu },
      });
    },
    // 删除
    handleDel(compId) {
      this.$confirm("确定删除该机构吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
          .then(() => {
            this.$post("/miniapp/company/delete", { compId: compId},
                3000, true, 2).then(
                (res) => {
                  this.getData();
                }
            );
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
     /**
     *  行政区划
     */
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areatreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    // 查看分享吗
    seeShare(row) {
      this.seeShareData.title = row.compName;
      this.seeShareData.url = row.qrcodeKey;
      this.seeShareDialog = true;
    },
    // 下载小程序码
    downloadShare() {
      window.open(this.seeShareData.url);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refrsh == true) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.operationControl > div > div > span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}
</style>
<style lang="less" scoped></style>
